<template>
  <div class="hello d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="row mt-5">
       
        <div class="col-md-6">
          <div class="content">
            <!-- Content goes here -->
            <p class="name details_container" >Hi, I'm Remya Raj A</p>
            <p class="details_container_heading">
              A Vue JS Developer & UI/UX Designer
            </p>
            <p class="details_container_text">
              My portfolio showcases a diverse range of projects where I've
              leveraged my expertise in Vue.js to build dynamic and interactive
              interfaces, coupled with my design sensibilities to ensure an
              exceptional user experience. From wireframing and prototyping to
              implementation and iteration, I approach each project with
              creativity, attention to detail, and a user-centric mindset.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Image goes here -->
          <img
            src="../assets/images/vectorImage.jpeg"
            alt="Placeholder Image"
            class="img-fluid"
          />
        </div>
      </div>

      <div class="row row-eq-height mt-5">
        <div class="col-md-4" @click="navigateToExternalLink">
          <!-- Content for first column -->
          <img
            src="../assets/images/image1.jpg"
            alt="Image 1"
            class="img-fluid"
          />
          <h1>Odd Sox</h1>
          <p>
            A CRO website redesign for a sock brand that specializes in selling
            unique designs featuring iconic shows, artists, and pop culture
            icons.
          </p>
        </div>
        <div class="col-md-4">
          <!-- Content for second column -->
          <img
            src="../assets/images/image2.jpg"
            alt="Image 2"
            class="img-fluid"
          />
          <h1>Odd Sox</h1>
          <p>
            A CRO website redesign for a sock brand that specializes in selling
            unique designs featuring iconic shows, artists, and pop culture
            icons.
          </p>
        </div>
        <div class="col-md-4">
          <!-- Content for third column -->
          <img
            src="../assets/images/image3.png"
            alt="Image 3"
            class="img-fluid"
          />
          <h1>Odd Sox</h1>
          <p>
            A CRO website redesign for a sock brand that specializes in selling
            unique designs featuring iconic shows, artists, and pop culture
            icons.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  methods: {
    navigateToExternalLink() {
      // Define the external link
      const externalLink = 'https://www.figma.com/file/rf4nhkngBZCaBjUiKi9IAr/Untitled?type=design&node-id=0-1&mode=design&t=CMFflMYjFqi91kyP-0';
      
      // Open the external link in a new tab
      window.open(externalLink, '_blank');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.details_container_heading{text-align: left;font-size: 24px; font-weight: 900}
.details_container_text{text-align: left}
.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

.row-eq-height > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.row-eq-height img {
  flex: 1;
}

.name {
  font-size: 48px;
  color: rgb(36, 107, 158);
  font-weight: bolder;
  text-align: left;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
